<template>
  <div class="main">
    <h2>{{ $t('msg.simulator.title') }}</h2>
    <p class="my-4">{{ $t('msg.simulator.subtitle') }}</p>
    <div class="row mb-4">
      <div class="col-12 col-md-6">
        <!-- Search -->
        <a-input-search
          v-model="zip"
          :placeholder="$t('msg.simulator.placeholder')"
          :enter-button="$t('msg.simulator.submit')"
          size="large"
          @search="quote" />
      </div>
    </div>
    <div class="card">
      <div class="card-header pt-4">
        <h5>
          {{ $t('msg.simulator.methods') }}
        </h5>
      </div>
      <div class="card-body p-0">
        <a-list
          :data-source="results"
          :pagination="false">
          <a-list-item
            slot="renderItem"
            slot-scope="item"
            class="p-4">
            <a-list-item-meta>
              <!-- Avatar -->
              <a-avatar
                class="d-none d-lg-block"
                slot="avatar"
                shape="square"
                size="large"
                :src="`resources/images/methods/${item.method}.png`" />
              <!-- Title -->
              <div slot="title">
                <h6>
                  {{ item.name }}
                </h6>
                <h5>
                  {{ item.title }}
                </h5>
              </div>
              <!-- ETA  -->
              <div slot="description" class="text-secondary">
                <div>
                  <a-icon type="clock-circle" class="mr-1" />
                  {{ $t('msg.simulator.calendar') }} {{ item.min_date | calendar }} {{ $t('and') }} {{ item.max_date | calendar }}
                </div>
              </div>
            </a-list-item-meta>
            <!-- Rate -->
            <h3 class="px-4">
              {{ item.rate | currency }}
            </h3>
          </a-list-item>
        </a-list>
      </div>
    </div>
  </div>
</template>
<script>
import lms from '@/api/lms'
export default {
  name: 'Simulator',
  data: function () {
    return {
      zip: undefined,
      results: [],
    }
  },
  methods: {
    quote() {
      if (this.zip) {
        const params = { account: this.account, zip: this.zip }
        return lms.quote.quote(params)
          .then(response => (this.results = response.data))
      }
    },
  },
}
</script>
